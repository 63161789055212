<template>
  <scroller
    :on-refresh="refresh"
  >
    <h1 class="text-primary mt-2">
      {{ $t('pages.shipment.now.name') }}
    </h1>
    <p class="text-black-25 mt-0 mb-2 pb-50">
      {{
        lastPullFormat !== null ? $t('pages.shipment.now.updated-at', { date: lastPullFormat }) : $t('therms.loading')
      }}
    </p>

    <div class="mt-2 pt-75 mb-3">
      <template v-if="error">
        <div
          class="mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.shipment.now.loading-error') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="refresh(() => {}, true)"
            >
              {{ $t('therms.retry') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!isFetch">
          <div class="d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else>
          <template
            v-if="has"
          >
            <template v-if="!isTablet">
              <div
                class="animate__animated animate__fadeInUp"
              >
                <ProductionCardResume
                  v-for="production in stats"
                  :id="production.id"
                  :key="production.id"
                  class="mb-1"
                  :emoji="production.emoji"
                  :name="production.name"
                  :total-weight="production.total_weight"
                  :quantity="production.quantity"
                  :go-to="()=>goToOne(production)"
                />
                <ProductionCardDistribution
                  v-if="chart !== null"
                  :series="chart.series"
                  :labels="chart.labels"
                  :colors="chart.colors"
                />
                <ShipmentListCard class="mt-3" />
              </div>
            </template>
            <template v-else>
              <b-row class="animate__animated animate__fadeInUp">
                <b-col
                  sm="12"
                  lg="5"
                >
                  <ProductionCardDistribution
                    v-if="chart !== null"
                    :series="chart.series"
                    :labels="chart.labels"
                    :colors="chart.colors"
                  />
                </b-col>
                <b-col
                  sm="12"
                  lg="7"
                >
                  <ShipmentListCard class="mb-3" />

                  <ProductionCardResume
                    v-for="production in stats"
                    :id="production.id"
                    :key="production.id"
                    class="mb-1"
                    :emoji="production.emoji"
                    :name="production.name"
                    :total-weight="production.total_weight"
                    :quantity="production.quantity"
                    :go-to="()=>goToOne(production)"
                  />
                </b-col>
              </b-row>
            </template>
          </template>
          <template v-else>
            <div class="text-center px-50">
              <span style="font-size: 6.5em">
                🚧
              </span>
              <h7 class="mt-1">
                {{ $t("therms.no-shipment") }}
              </h7>
              <p class="text-black-50 mt-0 pb-50">
                {{ $t('pages.shipment.now.no-data') }}
              </p>

              <div class="mb-2">
                <TouchButton
                  v-if="hasWithTrashed"
                  size="xs"
                  @action="$router.push({ name:ROUTE_NAME_NOW_LIST })"
                >
                  {{ $t('therms.see-deleted-shipment') }}
                </TouchButton>
              </div>
            </div>
          </template>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import ProductionCardResume from '@/views/components/ProductionCardResume.vue'
import ProductionCardDistribution from '@/views/components/ProductionCardDistribution.vue'
import ShipmentListCard from '@/views/pages/Now/components/ShipmentListCard.vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import { mapGetters } from 'vuex'
import { BSpinner, BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { NAME_NOW_LIST, NAME_NOW_ONE } from '@/router/routes/now'

export default {
  components: {
    ProductionCardResume,
    ProductionCardDistribution,
    ShipmentListCard,
    TouchButton,
    BSpinner,
    BRow,
    BCol,
  },
  data() {
    return {
      // ui
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      list: 'shipmentsNow/getList',
      count: 'shipmentsNow/count',
      isFetch: 'shipmentsNow/isFetch',
      /** @type {moment} lastPull */
      lastPull: 'shipmentsNow/getLastPull',
      stats: 'shipmentsNow/getStats',
      has: 'shipmentsNow/has',
      hasWithTrashed: 'shipmentsNow/hasWithTrashed',
    }),
    /**
     * @returns {boolean}
     */
    isTablet() {
      return window.isTablet()
    },
    /**
     * @returns {string}
     * @constructor
     */
    ROUTE_NAME_NOW_LIST() {
      return NAME_NOW_LIST
    },
    /**
     * @returns {string|null}
     */
    lastPullFormat() {
      if (!this.lastPull) return null
      return (`${this.lastPull?.format('LT')}`).toLowerCase()
    },
    /**
     * @returns {Object}
     */
    chart() {
      if (!this.has) return null

      return {
        series: Object.values(this.stats)
          .map(element => Number(element.total_weight.toFixed(2))),
        colors: Object.values(this.stats)
          .map(element => element.color),
        labels: Object.values(this.stats)
          .map(element => element.name),
      }
    },
  },
  async mounted() {
    this.error = false

    this.emitter.on('appStateChange', async isActive => {
      if (this.needFetch(isActive)) await this.fetch(true)
    })

    try {
      if (this.needFetch(true)) await this.fetch(true)
    } catch (err) {
      this.error = true
      throw err
    }
  },
  beforeDestroy() {
    this.emitter.off('appStateChange')
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
    /**
     * @param {ExpeditionSheet|any} element
     */
    goToOne(element) {
      this.$router.push({
        name: NAME_NOW_ONE,
        params: {
          crop_id: element.id.toString(),
        },
      })
    },
    /**
     * @param {Boolean} isActive
     * @returns {boolean}
     */
    needFetch(isActive = false) {
      if (this.$store.getters['shipmentsNow/getLastPull'] === null) return true

      return (isActive && (this.$store.getters['shipmentsNow/getLastPull']
        .locale('en')
        .format('YYYY-MM-DD') !== this.$moment()
        .locale('en')
        .format('YYYY-MM-DD')
          || this.$moment()
            .diff(this.$store.getters['shipmentsNow/getLastPull'], 'minutes') >= 5))
    },
    /**
     * @param {Boolean} force
     */
    async fetch(force = false) {
      if (force) {
        this.$store.commit('shipmentsNow/clear')
      }

      if (!this.$store.getters['shipmentsNow/isFetch']) {
        await this.$store.dispatch('shipmentsNow/fetch', true)
      }
    },
    /**
     * @param {Function} finishToRefresh
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async refresh(finishToRefresh, force = false) {
      this.error = false

      try {
        if (force) this.$store.commit('shipmentsNow/clear')
        await this.$store.dispatch('shipmentsNow/fetch')
        finishToRefresh()
      } catch (err) {
        this.error = true
        finishToRefresh()

        throw err
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

.card.card-chart {
  .card-title {
    margin-bottom: 10px;
  }
}
</style>
