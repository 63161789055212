<template>
  <touch-card
    class="pb-0 card-gradient"
    :bg-variant="'primary'"
    text-variant="white"
    @action="$router.push({ name:ROUTE_NAME_NOW_LIST })"
  >
    <b-row class="pb-0 d-flex">
      <b-col
        class="d-flex justify-content-between flex-column ml-25 w-100"
      >
        <div class="d-flex justify-content-between">
          <div class="flex-fill">
            <h6 class="mb-1 text-light">
              🚚 &nbsp; {{ $t('pages.shipment.name') }}
            </h6>
            <div>
              <span
                class="font-weight-light"
              >{{ $t('pages.shipment.go-to-list') }}</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </touch-card>
</template>

<script>
import TouchCard from '@core/components/touch-card/TouchCard.vue'
import { BCol, BRow } from 'bootstrap-vue'
import { NAME_NOW_LIST } from '@/router/routes/now'

export default {
  components: {
    BCol,
    BRow,
    TouchCard,
  },
  data() {
    return {}
  },
  computed: {
    ROUTE_NAME_NOW_LIST() {
      return NAME_NOW_LIST
    },
  },
  mounted() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../../@core/scss/base/components/_include';

</style>
